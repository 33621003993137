define("shared/utils/keycodes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    ARROW_UP: 38,
    ARROW_DOWN: 40,
    ARROW_RIGHT: 39,
    ARROW_LEFT: 37,
    ENTER: 13,
    DELETE: 46,
    BACKSPACE: 8,
    ESCAPE: 27,
    TAB: 9,
    DASH: 45,
    SHIFT: 16,
    SPACE: 32
  };
  _exports.default = _default;
});